<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <a-typography-title>Real-Time Order Assignment</a-typography-title>
      <a-typography-title type="secondary" :heading="6">
        ADS provides real-time order assignment to ensure prompt action. As new orders come
        in, our intelligent system assigns them to the most suitable driver based on factors such as proximity, capacity,
        and expertise. This automated process eliminates delays and optimizes driver utilization, allowing for swift order
        allocation.
      </a-typography-title>
      <a-typography-title type="secondary" :heading="6">
        Real-time order assignment is a key feature of our intelligent delivery management system,
        designed to optimize efficiency and enhance the speed of order processing. By leveraging advanced algorithms and
        real-time data analysis, our system intelligently assigns orders to the most suitable drivers in the field. Here's
        how real-time order assignment works and the benefits it offers.
      </a-typography-title>
      <div class="step">
        <a-typography-title :heading="5">
          1. Automated Order Distribution
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          As soon as an order is received, our intelligent delivery management system automatically evaluates various
          factors such as driver availability, proximity to the pickup location, and workload capacity. Based on these
          criteria, the system intelligently assigns the order to the most appropriate driver, ensuring efficient
          distribution and minimizing the time between order placement and pickup.
        </a-typography-paragraph>
        <a-typography-title :heading="5">
          2. Proximity-Based Assignment
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          The system takes into account the geographical location of drivers in relation to the pickup point. By assigning
          orders to the closest available driver, we optimize the overall delivery route, reducing unnecessary travel time
          and improving overall delivery speed. This proximity-based assignment ensures that orders are promptly assigned
          to drivers who can quickly reach the pickup location.
        </a-typography-paragraph>
        <a-typography-title :heading="5">
          3. Dynamic Workload Balancing
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Our system continuously monitors the workload of each driver and intelligently distributes orders to balance the
          workload evenly. By considering factors such as driver capacity, time constraints, and delivery priorities, the
          system ensures that no driver is overwhelmed with excessive orders while others remain underutilized. This
          dynamic
          workload balancing maximizes efficiency and prevents bottlenecks in the delivery process.
        </a-typography-paragraph>
        <a-typography-title :heading="5">
          4. Driver Skill Matching
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          In addition to proximity and workload considerations, our system also takes into account driver skills and
          expertise when assigning orders. For example, if a specific order requires special handling or knowledge, such
          as
          fragile items or perishable goods, the system will prioritize assigning it to drivers who have the necessary
          skills and experience. This ensures that orders are assigned to drivers who can handle them with care and
          precision.
        </a-typography-paragraph>
        <a-typography-title :heading="5">
          5. Real-Time Data and Analytics
        </a-typography-title>
        <a-typography-paragraph type="secondary">
          Our intelligent system continuously analyzes real-time data, including order volume, traffic conditions, driver
          availability, and customer preferences. By leveraging this data, the system adapts and adjusts the order
          assignment process to optimize efficiency and delivery speed. It can quickly respond to changes in demand,
          traffic
          patterns, or unforeseen circumstances, ensuring orders are assigned in the most efficient manner.
        </a-typography-paragraph>
      </div>

      <a-typography-title :heading="4">
        Benefits of Real-Time Order Assignment
      </a-typography-title>
      <a-typography-title :heading="6">
        <em>Faster Order Fulfillment</em>
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Real-time order assignment reduces the time between order placement and pickup, enabling faster order fulfillment.
        This means your customers receive their packages sooner, enhancing their satisfaction and loyalty.
      </a-typography-paragraph>
      <a-typography-title :heading="6">
        <em>Efficient Resource Utilization</em>
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        By intelligently assigning orders based on driver proximity and workload capacity, real-time order assignment
        maximizes resource utilization. It minimizes idle time for drivers and ensures optimal use of available delivery
        resources, improving operational efficiency and reducing costs.
      </a-typography-paragraph>
      <a-typography-title :heading="6">
        <em>Improved Delivery Accuracy</em>
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        The automated nature of real-time order assignment reduces the risk of manual errors or oversight.
        It ensures that orders are consistently assigned to the most suitable drivers, reducing the likelihood of
        miscommunication or incorrect deliveries.
      </a-typography-paragraph>
      <a-typography-title :heading="6">
        <em>Enhanced Customer Experience</em>
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Real-time order assignment enables faster and more efficient deliveries, contributing to an
        exceptional customer experience. Customers appreciate timely and reliable service, leading to increased
        satisfaction and the potential for positive word-of-mouth referrals.
      </a-typography-paragraph>
      <a-typography-title :heading="6" style="margin-top:60px;font-weight: 700;">
        Real-time order assignment is a crucial component of our intelligent delivery management system, enabling
        optimized efficiency, faster order processing, and improved customer satisfaction. With Tailored Delivery
        Solutions, you can experience the benefits of real-time order assignment and elevate your delivery operations to
        new heights.
      </a-typography-title>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku14.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .step {
    padding-left: 24px;
  }
}
</style>
